import * as React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { useGetActiveDistributionsQuery } from "../generated/graphql";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { Badge } from "../components/ui/badge";
import { Toaster } from "../components/ui/toaster";
import { useToast } from "../components/ui/use-toast";
import { useEffect } from "react";
import { formatNumber } from "../lib/formatNumbers";
import { Navbar } from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/ui/button";

export function Dashboard() {
  const navigate = useNavigate();
  const { toast } = useToast();

  const [
    { data: distributionsToApprove, error: distributionsToApproveError },
    refetchDistributionsToApprove,
  ] = useGetActiveDistributionsQuery({
    requestPolicy: "network-only",
  });

  useEffect(() => {
    if (distributionsToApproveError) {
      toast({
        title: "Error",
        description: distributionsToApproveError.message,
        variant: "destructive",
      });
    }
  }, [distributionsToApproveError, toast]);

  return (
    <div className="flex min-h-screen w-full flex-col">
      <Navbar />
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <div className="grid gap-4 md:gap-8 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
          {(distributionsToApprove?.getActiveDistributions.length ?? 0) > 0 ? (
            <Card className="xl:col-span-2">
              <CardHeader className="flex flex-row items-center">
                <div className="grid gap-2">
                  <CardTitle>Distributions to execute</CardTitle>
                  <CardDescription>Recent transactions</CardDescription>
                </div>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>id</TableHead>
                      <TableHead>Status</TableHead>
                      <TableHead>Organization</TableHead>
                      <TableHead>Total Amount</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {distributionsToApprove?.getActiveDistributions?.map(
                      (transaction, idx) => (
                        <TableRow
                          key={idx}
                          onClick={async () => {
                            // setTransactionIdAndBump([
                            //   transaction.id,
                            //   transaction.bump,
                            // ]);
                            navigate(`/distribution/${transaction.id}`);
                          }}
                        >
                          <TableCell>D{transaction.id}</TableCell>
                          <TableCell>
                            <Badge className="text-xs" variant="outline">
                              {transaction.status}
                            </Badge>
                          </TableCell>
                          <TableCell>{transaction.organizationName}</TableCell>
                          <TableCell>
                            {formatNumber(transaction.totalAmount)}$
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          ) : (
            <Card className="xl:col-span-2">
              <CardHeader className="flex flex-row items-center">
                <div className="grid gap-2">
                  <CardTitle>No distributions to execute</CardTitle>
                </div>
              </CardHeader>
              <CardContent>
                <div className="grid gap-4">
                  <p>You will be alerted on discord</p>
                  <Button
                    style={{ width: 100 }}
                    onClick={() => refetchDistributionsToApprove()}
                  >
                    Refresh
                  </Button>
                </div>
              </CardContent>
            </Card>
          )}
        </div>
      </main>
      <Toaster />
    </div>
  );
}
