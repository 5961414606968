import { useNavigate } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import {
  useCreateOrganizationMutation,
  // useGetLargestBumpQuery,
  useGetOrganizationsQuery,
} from "../generated/graphql";
import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import {
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Table,
} from "../components/ui/table";
import { Button } from "../components/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../components/ui/pagination";
import { useToast } from "../components/ui/use-toast";
import { Toaster } from "../components/ui/toaster";

export function Organizations() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [page, setPage] = useState(1);
  // form data, name, feeBps, bump, ethWalletAddress, btcWalletAddress
  const [name, setName] = useState("");
  const [feeBps, setFeeBps] = useState(0);
  const [bump, setBump] = useState(0);
  const [ethWalletAddress, setEthWalletAddress] = useState("");
  const [btcWalletAddress, setBtcWalletAddress] = useState("");

  const [{ data: organizationsData, error: organizationError }] =
    useGetOrganizationsQuery({
      variables: { page: page },
    });
  const [
    { data: createOrganizationData, error: createOrganizationError },
    createOrganization,
  ] = useCreateOrganizationMutation();
  // const [{ data: biggestBump, error: biggestBumpError }, refetchBiggestBump] =
  //   useGetLargestBumpQuery();

  // useEffect(() => {
  //   if (biggestBump) {
  //     setBump(biggestBump.getLargestBump);
  //     (async () => {
  //       setEthWalletAddress(
  //         (await eth?.getAddress(`44'/60'/0'/0/${biggestBump.getLargestBump}`))
  //           ?.address ?? ""
  //       );
  //     })();
  //   }
  // }, [biggestBump, eth]);

  useEffect(() => {
    if (organizationError) {
      toast({
        title: "Error",
        description: organizationError.message,
        variant: "destructive",
      });
    }

    if (createOrganizationError) {
      toast({
        title: "Error",
        description: createOrganizationError.message,
        variant: "destructive",
      });
    }

    // if (biggestBumpError) {
    //   toast({
    //     title: "Error",
    //     description: biggestBumpError.message,
    //     variant: "destructive",
    //   });
    // }

    if (createOrganizationData) {
      toast({
        title: "Success",
        description: "Organization created",
        variant: "default",
      });

      setName("");
      setFeeBps(0);
      // refetchBiggestBump();
    }
  }, [
    // biggestBumpError,
    // bump,
    createOrganizationData,
    createOrganizationError,
    organizationError,
    // refetchBiggestBump,
    toast,
  ]);

  return (
    <div className="flex min-h-screen w-full flex-col">
      <Navbar />
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <Card className="xl:col-span-2">
          <CardHeader className="flex flex-row items-center justify-between">
            <div className="grid gap-2">
              <CardTitle>Organization</CardTitle>
              {/* <CardDescription>Recent transactions</CardDescription> */}
            </div>
            <Sheet>
              <SheetTrigger asChild>
                <Button
                  variant="outline"
                  className="bg-black text-white"
                  onClick={() => {}}
                >
                  Add Organization
                </Button>
              </SheetTrigger>
              <SheetContent side="bottom">
                <SheetHeader>
                  <SheetTitle>Add Organization</SheetTitle>
                </SheetHeader>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="name" className="text-right">
                      Name
                    </Label>
                    <Input
                      id="name"
                      placeholder="Organization Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      className="col-span-3"
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="feeBps" className="text-right">
                      FeeBps
                    </Label>
                    <Input
                      id="feeBps"
                      type="number"
                      placeholder="0"
                      value={feeBps}
                      onChange={(e) => {
                        setFeeBps(parseInt(e.target.value));
                      }}
                      className="col-span-3"
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="bump" className="text-right">
                      Bump
                    </Label>
                    <Input
                      id="bump"
                      type="number"
                      placeholder="0"
                      onChange={(e) => {
                        setBump(parseInt(e.target.value));
                      }}
                      value={bump}
                      className="col-span-3"
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="ethWalletAddress" className="text-right">
                      Eth Wallet Address
                    </Label>
                    <Input
                      id="ethWalletAddress"
                      placeholder="0x1234567890"
                      value={ethWalletAddress}
                      onChange={(e) => {
                        setEthWalletAddress(e.target.value);
                      }}
                      className="col-span-3"
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="btcWalletAddress" className="text-right">
                      Btc Wallet Address
                    </Label>
                    <Input
                      id="btcWalletAddress"
                      placeholder="0x1234567890"
                      value={btcWalletAddress}
                      onChange={(e) => {
                        setBtcWalletAddress(e.target.value);
                      }}
                      className="col-span-3"
                    />
                  </div>
                </div>
                <SheetFooter>
                  <SheetClose asChild>
                    <Button
                      type="submit"
                      onClick={async () => {
                        await createOrganization({
                          name,
                          feeBps,
                          bump,
                          ethWalletAddress,
                          btcWalletAddress,
                        });
                      }}
                    >
                      Save changes
                    </Button>
                  </SheetClose>
                </SheetFooter>
              </SheetContent>
            </Sheet>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Fee Bps</TableHead>
                  <TableHead>Balances</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {organizationsData?.getOrganizations?.map(
                  (organization, idx) => (
                    <TableRow
                      key={idx}
                      onClick={async () => {
                        // GOTO ORGANIZATION
                        navigate(`/organizations/${organization.id}`);
                      }}
                    >
                      <TableCell>{organization.name}</TableCell>
                      <TableCell>{organization.feeBps}</TableCell>
                      <TableCell>
                        {organization.balance.map((b) => b.mint).join(",")}
                      </TableCell>
                      {/* {organization.balance.map((balance, idx) => (
                        <TableCell key={idx}>
                          Mint:{balance.mint}
                          CREDIT:{formatNumber(balance.credit)}
                          WITHDRAWL:
                          {formatNumber(balance.availableForWithdrawal)}
                        </TableCell>
                      ))} */}
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() => {
                      setPage(page - 1);
                    }}
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink>{page}</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationNext
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </CardContent>
        </Card>
      </main>
      <Toaster />
    </div>
  );
}
