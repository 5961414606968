import TransportWebBLE from "@ledgerhq/hw-transport-web-ble";
import TransportWebUSB from "@ledgerhq/hw-transport-webusb";
import { Loader2, CircleXIcon, CircleCheckBig } from "lucide-react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "./ui/alert-dialog";
import Transport from "@ledgerhq/hw-transport";
import { useState } from "react";

export enum TransactionStatus {
  CONNECT,
  OPENAPP,
  SIGN,
  BROADCAST,
  CONFIRMING,
  FAILED,
  SUCCESS,
}

export const useTransactor = () => {
  const [transactionStatus, setTransactionStatus] = useState<TransactionStatus>(
    TransactionStatus.CONNECT
  );

  const [txHash, setTxHash] = useState<string | null>(null);

  return {
    transactionStatus,
    setTransactionStatus,
    txHash,
    setTxHash,
  };
};

interface Props {
  title: string;
  open: boolean;
  txHash: string | null;
  setTxHash: (txHash: string | null) => void;
  transactionStatus: TransactionStatus;
  setTransactionStatus: (transactionStatus: TransactionStatus) => void;
  cleanUp: () => void;
  executeTransaction: (transport: Transport) => Promise<void>;
}

export function Transactor({
  title,
  open,
  txHash,
  transactionStatus,
  setTxHash,
  setTransactionStatus,
  cleanUp,
  executeTransaction,
}: Props) {
  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription
            className={txHash ? "cursor-pointer" : ""}
            onClick={() => {
              if (txHash) {
                window.open(
                  `${process.env.REACT_APP_ETH_EXPLORER_URL}${txHash}`,
                  "_blank"
                );
              }
            }}
          >
            {(() => {
              switch (transactionStatus) {
                case TransactionStatus.CONNECT:
                  return "Connect your ledger device";
                case TransactionStatus.OPENAPP:
                  return "Unlock and open the Ethereum app on your ledger device";
                case TransactionStatus.SIGN:
                  return "Signing transaction";
                case TransactionStatus.BROADCAST:
                  return "Broadcasting transaction";
                case TransactionStatus.CONFIRMING:
                  return "Confirming transaction";
                case TransactionStatus.FAILED:
                  return "Transaction failed";
                case TransactionStatus.SUCCESS:
                  return `Transaction successful: ${txHash?.slice(0, 18)}...`;
              }
            })()}
          </AlertDialogDescription>
          {/* Loading */}
          {(() => {
            switch (transactionStatus) {
              case TransactionStatus.CONNECT:
                return null;
              case TransactionStatus.OPENAPP:
              case TransactionStatus.SIGN:
              case TransactionStatus.BROADCAST:
              case TransactionStatus.CONFIRMING:
                return (
                  <div className="flex justify-center items-center">
                    <Loader2 className="h-12 w-12 animate-spin" />
                  </div>
                );
              case TransactionStatus.FAILED:
                return (
                  <div className="flex justify-center items-center">
                    <CircleXIcon className="h-12 w-12" />
                  </div>
                );
              case TransactionStatus.SUCCESS:
                return (
                  <div className="flex justify-center items-center">
                    <CircleCheckBig className="h-12 w-12" />
                  </div>
                );
            }
          })()}
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            disabled={transactionStatus !== TransactionStatus.CONNECT}
            onClick={() => {
              //   setWithdrawMint(null);
              cleanUp();
            }}
          >
            Cancel
          </AlertDialogCancel>
          {(TransactionStatus.CONNECT === transactionStatus ||
            TransactionStatus.OPENAPP === transactionStatus) && (
            <>
              <AlertDialogAction
                onClick={async () => {
                  const transport = await TransportWebBLE.create();

                  // await executeWithdrawl(transport, withdrawMint ?? 0);
                  await executeTransaction(transport);
                }}
              >
                Connect using Bluetooth
              </AlertDialogAction>
              <AlertDialogAction
                onClick={async () => {
                  const transport = await TransportWebUSB.create();

                  // await executeWithdrawl(transport, withdrawMint ?? 0);
                  await executeTransaction(transport);
                }}
              >
                Connect using USB
              </AlertDialogAction>
            </>
          )}
          {TransactionStatus.SUCCESS === transactionStatus && (
            <AlertDialogAction
              onClick={() => {
                // setWithdrawMint(null);
                cleanUp();
                setTransactionStatus(TransactionStatus.CONNECT);
                setTxHash(null);
              }}
            >
              Close
            </AlertDialogAction>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
