import { GraphQLResolveInfo } from 'graphql';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AdminLog = {
  __typename?: 'AdminLog';
  action: Scalars['String']['output'];
  date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type Balance = {
  __typename?: 'Balance';
  availableForWithdrawal: Scalars['String']['output'];
  credit: Scalars['String']['output'];
  mint: Scalars['String']['output'];
  mintId: Scalars['Int']['output'];
  onHold: Scalars['String']['output'];
};

export type DetailedDistribution = {
  __typename?: 'DetailedDistribution';
  amount: Scalars['String']['output'];
  approvedBy?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  gas?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  mint: Scalars['String']['output'];
  organizationBump: Scalars['Int']['output'];
  processedAt?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  totalAmount?: Maybe<Scalars['String']['output']>;
  transactions: Array<DistributionTransaction>;
  txHash?: Maybe<Scalars['String']['output']>;
};

export type Distribution = {
  __typename?: 'Distribution';
  bump: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
  status: Scalars['String']['output'];
  totalAmount: Scalars['String']['output'];
  transactions: Array<Maybe<DistributionTransaction>>;
};

export type DistributionTransaction = {
  __typename?: 'DistributionTransaction';
  amount: Scalars['String']['output'];
  invoiceId: Scalars['String']['output'];
  to: Scalars['String']['output'];
  toName: Scalars['String']['output'];
};

export type Mint = {
  __typename?: 'Mint';
  address: Scalars['String']['output'];
  decimals: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addUser: User;
  approvalTransaction: Scalars['String']['output'];
  cancelDistribution: Scalars['Int']['output'];
  createOrganization: Organization;
  distributionTransaction: Scalars['String']['output'];
  executeWithdrawal: Withdrawal;
  logout: Scalars['Boolean']['output'];
  resetPassword: User;
  toggleUserStatus: User;
  updateOrganizationFeeBps: Organization;
  updateOrganizationName: Organization;
  updateUserRole: User;
  withdrawlTransaction: Scalars['String']['output'];
};


export type MutationAddUserArgs = {
  email: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  role: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationApprovalTransactionArgs = {
  id: Scalars['Int']['input'];
};


export type MutationCancelDistributionArgs = {
  id: Scalars['Int']['input'];
};


export type MutationCreateOrganizationArgs = {
  btcWalletAddress: Scalars['String']['input'];
  bump: Scalars['Int']['input'];
  ethWalletAddress: Scalars['String']['input'];
  feeBps: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};


export type MutationDistributionTransactionArgs = {
  id: Scalars['Int']['input'];
};


export type MutationExecuteWithdrawalArgs = {
  mint: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  tx: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  userId: Scalars['String']['input'];
};


export type MutationToggleUserStatusArgs = {
  userId: Scalars['String']['input'];
};


export type MutationUpdateOrganizationFeeBpsArgs = {
  feeBps: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationUpdateOrganizationNameArgs = {
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationUpdateUserRoleArgs = {
  role: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationWithdrawlTransactionArgs = {
  mint: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
};

export type Organization = {
  __typename?: 'Organization';
  balance: Array<Balance>;
  btcWalletAddress: Scalars['String']['output'];
  bump: Scalars['Int']['output'];
  ethWalletAddress: Scalars['String']['output'];
  feeBps: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  getActiveDistributions: Array<Distribution>;
  getDistribution: DetailedDistribution;
  getLargestBump: Scalars['Int']['output'];
  getLogHistoryOfAdmin: Array<AdminLog>;
  getLogHistoryOfOrganization: Array<UserLog>;
  getLogHistoryOfUser: Array<UserLog>;
  getNonce: Scalars['Int']['output'];
  getOrganization: Organization;
  getOrganizations: Array<Organization>;
  getTransactionsHistory: Array<Transaction>;
  getUser: User;
  getUsersOfOrganization: Array<User>;
  getWithdrawals: Array<Withdrawal>;
};


export type QueryGetDistributionArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetLogHistoryOfAdminArgs = {
  page: Scalars['Int']['input'];
};


export type QueryGetLogHistoryOfOrganizationArgs = {
  organizationId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
};


export type QueryGetLogHistoryOfUserArgs = {
  page: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};


export type QueryGetOrganizationArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetOrganizationsArgs = {
  page: Scalars['Int']['input'];
};


export type QueryGetTransactionsHistoryArgs = {
  organizationId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
};


export type QueryGetUserArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetUsersOfOrganizationArgs = {
  organizationId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
};


export type QueryGetWithdrawalsArgs = {
  page: Scalars['Int']['input'];
};

export type Transaction = {
  __typename?: 'Transaction';
  availableForWithdrawal?: Maybe<Scalars['String']['output']>;
  credit?: Maybe<Scalars['String']['output']>;
  creditDelta?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isProcessed: Scalars['Boolean']['output'];
  status: Scalars['String']['output'];
  txHash?: Maybe<Scalars['String']['output']>;
  type: TransactionType;
};

export enum TransactionType {
  Distribution = 'DISTRIBUTION',
  Topup = 'TOPUP',
  Withdrawal = 'WITHDRAWAL'
}

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  role: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type UserLog = {
  __typename?: 'UserLog';
  action: Scalars['String']['output'];
  date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  organizationName: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type Withdrawal = {
  __typename?: 'Withdrawal';
  amount: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  organizationId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  txHash?: Maybe<Scalars['String']['output']>;
};

export type GetNonceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNonceQuery = { __typename?: 'Query', getNonce: number };

export type GetLargestBumpQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLargestBumpQuery = { __typename?: 'Query', getLargestBump: number };

export type GetOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type GetOrganizationQuery = { __typename?: 'Query', getOrganization: { __typename?: 'Organization', id: string, name: string, feeBps: number, bump: number, ethWalletAddress: string, btcWalletAddress: string, balance: Array<{ __typename?: 'Balance', credit: string, onHold: string, mint: string, mintId: number, availableForWithdrawal: string }> } };

export type GetOrganizationsQueryVariables = Exact<{
  page: Scalars['Int']['input'];
}>;


export type GetOrganizationsQuery = { __typename?: 'Query', getOrganizations: Array<{ __typename?: 'Organization', id: string, name: string, feeBps: number, ethWalletAddress: string, btcWalletAddress: string, balance: Array<{ __typename?: 'Balance', credit: string, onHold: string, mint: string, mintId: number, availableForWithdrawal: string }> }> };

export type GetUserQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean, role: string } };

export type GetUsersOfOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
}>;


export type GetUsersOfOrganizationQuery = { __typename?: 'Query', getUsersOfOrganization: Array<{ __typename?: 'User', userId: string, username: string, email: string, isActive: boolean, role: string }> };

export type GetLogHistoryOfOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
}>;


export type GetLogHistoryOfOrganizationQuery = { __typename?: 'Query', getLogHistoryOfOrganization: Array<{ __typename?: 'UserLog', id: number, organizationName: string, username: string, action: string, date: string }> };

export type GetLogHistoryOfUserQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
}>;


export type GetLogHistoryOfUserQuery = { __typename?: 'Query', getLogHistoryOfUser: Array<{ __typename?: 'UserLog', id: number, organizationName: string, username: string, action: string, date: string }> };

export type GetTransactionsHistoryQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
}>;


export type GetTransactionsHistoryQuery = { __typename?: 'Query', getTransactionsHistory: Array<{ __typename?: 'Transaction', id: number, type: TransactionType, creditDelta?: string | null, credit?: string | null, availableForWithdrawal?: string | null, date: string, isProcessed: boolean, txHash?: string | null, status: string }> };

export type GetActiveDistributionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveDistributionsQuery = { __typename?: 'Query', getActiveDistributions: Array<{ __typename?: 'Distribution', id: number, name: string, bump: number, totalAmount: string, status: string, organizationName: string, transactions: Array<{ __typename?: 'DistributionTransaction', to: string, amount: string } | null> }> };

export type GetWithdrawalsQueryVariables = Exact<{
  page: Scalars['Int']['input'];
}>;


export type GetWithdrawalsQuery = { __typename?: 'Query', getWithdrawals: Array<{ __typename?: 'Withdrawal', id: number, organizationId: string, amount: string, status: string, txHash?: string | null }> };

export type GetLogHistoryOfAdminQueryVariables = Exact<{
  page: Scalars['Int']['input'];
}>;


export type GetLogHistoryOfAdminQuery = { __typename?: 'Query', getLogHistoryOfAdmin: Array<{ __typename?: 'AdminLog', id: number, username: string, action: string, date: string }> };

export type GetDistributionQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetDistributionQuery = { __typename?: 'Query', getDistribution: { __typename?: 'DetailedDistribution', id: number, amount: string, organizationBump: number, gas?: string | null, totalAmount?: string | null, createdBy: string, approvedBy?: string | null, createdAt: string, processedAt?: string | null, mint: string, status: string, txHash?: string | null, transactions: Array<{ __typename?: 'DistributionTransaction', to: string, invoiceId: string, toName: string, amount: string }> } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type ApprovalTransactionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type ApprovalTransactionMutation = { __typename?: 'Mutation', approvalTransaction: string };

export type DistributionTransactionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DistributionTransactionMutation = { __typename?: 'Mutation', distributionTransaction: string };

export type WithdrawlTransactionMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  mint: Scalars['Int']['input'];
}>;


export type WithdrawlTransactionMutation = { __typename?: 'Mutation', withdrawlTransaction: string };

export type CreateOrganizationMutationVariables = Exact<{
  name: Scalars['String']['input'];
  feeBps: Scalars['Int']['input'];
  bump: Scalars['Int']['input'];
  ethWalletAddress: Scalars['String']['input'];
  btcWalletAddress: Scalars['String']['input'];
}>;


export type CreateOrganizationMutation = { __typename?: 'Mutation', createOrganization: { __typename?: 'Organization', id: string, name: string, feeBps: number, ethWalletAddress: string, btcWalletAddress: string, balance: Array<{ __typename?: 'Balance', credit: string, onHold: string, mint: string, mintId: number, availableForWithdrawal: string }> } };

export type UpdateOrganizationNameMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type UpdateOrganizationNameMutation = { __typename?: 'Mutation', updateOrganizationName: { __typename?: 'Organization', id: string, name: string, feeBps: number, ethWalletAddress: string, btcWalletAddress: string, balance: Array<{ __typename?: 'Balance', credit: string, onHold: string, mint: string, mintId: number, availableForWithdrawal: string }> } };

export type UpdateOrganizationFeeBpsMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  feeBps: Scalars['Int']['input'];
}>;


export type UpdateOrganizationFeeBpsMutation = { __typename?: 'Mutation', updateOrganizationFeeBps: { __typename?: 'Organization', id: string, name: string, feeBps: number, ethWalletAddress: string, btcWalletAddress: string, balance: Array<{ __typename?: 'Balance', credit: string, onHold: string, mint: string, mintId: number, availableForWithdrawal: string }> } };

export type AddUserMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  username: Scalars['String']['input'];
  email: Scalars['String']['input'];
  role: Scalars['String']['input'];
}>;


export type AddUserMutation = { __typename?: 'Mutation', addUser: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean, role: string } };

export type UpdateUserRoleMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  role: Scalars['String']['input'];
}>;


export type UpdateUserRoleMutation = { __typename?: 'Mutation', updateUserRole: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean, role: string } };

export type ToggleUserStatusMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type ToggleUserStatusMutation = { __typename?: 'Mutation', toggleUserStatus: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean, role: string } };

export type ResetPasswordMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean, role: string } };

export type CancelDistributionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type CancelDistributionMutation = { __typename?: 'Mutation', cancelDistribution: number };


export const GetNonceDocument = gql`
    query getNonce {
  getNonce
}
    `;

export function useGetNonceQuery(options?: Omit<Urql.UseQueryArgs<GetNonceQueryVariables>, 'query'>) {
  return Urql.useQuery<GetNonceQuery, GetNonceQueryVariables>({ query: GetNonceDocument, ...options });
};
export const GetLargestBumpDocument = gql`
    query getLargestBump {
  getLargestBump
}
    `;

export function useGetLargestBumpQuery(options?: Omit<Urql.UseQueryArgs<GetLargestBumpQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLargestBumpQuery, GetLargestBumpQueryVariables>({ query: GetLargestBumpDocument, ...options });
};
export const GetOrganizationDocument = gql`
    query getOrganization($organizationId: String!) {
  getOrganization(organizationId: $organizationId) {
    id
    name
    feeBps
    bump
    ethWalletAddress
    btcWalletAddress
    balance {
      credit
      onHold
      mint
      mintId
      availableForWithdrawal
    }
  }
}
    `;

export function useGetOrganizationQuery(options: Omit<Urql.UseQueryArgs<GetOrganizationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>({ query: GetOrganizationDocument, ...options });
};
export const GetOrganizationsDocument = gql`
    query getOrganizations($page: Int!) {
  getOrganizations(page: $page) {
    id
    name
    feeBps
    ethWalletAddress
    btcWalletAddress
    balance {
      credit
      onHold
      mint
      mintId
      availableForWithdrawal
    }
  }
}
    `;

export function useGetOrganizationsQuery(options: Omit<Urql.UseQueryArgs<GetOrganizationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>({ query: GetOrganizationsDocument, ...options });
};
export const GetUserDocument = gql`
    query getUser($userId: String!) {
  getUser(userId: $userId) {
    userId
    username
    email
    isActive
    role
  }
}
    `;

export function useGetUserQuery(options: Omit<Urql.UseQueryArgs<GetUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserQuery, GetUserQueryVariables>({ query: GetUserDocument, ...options });
};
export const GetUsersOfOrganizationDocument = gql`
    query getUsersOfOrganization($organizationId: String!, $page: Int!) {
  getUsersOfOrganization(organizationId: $organizationId, page: $page) {
    userId
    username
    email
    isActive
    role
  }
}
    `;

export function useGetUsersOfOrganizationQuery(options: Omit<Urql.UseQueryArgs<GetUsersOfOrganizationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUsersOfOrganizationQuery, GetUsersOfOrganizationQueryVariables>({ query: GetUsersOfOrganizationDocument, ...options });
};
export const GetLogHistoryOfOrganizationDocument = gql`
    query getLogHistoryOfOrganization($organizationId: String!, $page: Int!) {
  getLogHistoryOfOrganization(organizationId: $organizationId, page: $page) {
    id
    organizationName
    username
    action
    date
  }
}
    `;

export function useGetLogHistoryOfOrganizationQuery(options: Omit<Urql.UseQueryArgs<GetLogHistoryOfOrganizationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLogHistoryOfOrganizationQuery, GetLogHistoryOfOrganizationQueryVariables>({ query: GetLogHistoryOfOrganizationDocument, ...options });
};
export const GetLogHistoryOfUserDocument = gql`
    query getLogHistoryOfUser($userId: String!, $page: Int!) {
  getLogHistoryOfUser(userId: $userId, page: $page) {
    id
    organizationName
    username
    action
    date
  }
}
    `;

export function useGetLogHistoryOfUserQuery(options: Omit<Urql.UseQueryArgs<GetLogHistoryOfUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLogHistoryOfUserQuery, GetLogHistoryOfUserQueryVariables>({ query: GetLogHistoryOfUserDocument, ...options });
};
export const GetTransactionsHistoryDocument = gql`
    query getTransactionsHistory($organizationId: String!, $page: Int!) {
  getTransactionsHistory(organizationId: $organizationId, page: $page) {
    id
    type
    creditDelta
    credit
    availableForWithdrawal
    date
    isProcessed
    txHash
    status
  }
}
    `;

export function useGetTransactionsHistoryQuery(options: Omit<Urql.UseQueryArgs<GetTransactionsHistoryQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTransactionsHistoryQuery, GetTransactionsHistoryQueryVariables>({ query: GetTransactionsHistoryDocument, ...options });
};
export const GetActiveDistributionsDocument = gql`
    query getActiveDistributions {
  getActiveDistributions {
    id
    name
    bump
    totalAmount
    status
    organizationName
    transactions {
      to
      amount
    }
  }
}
    `;

export function useGetActiveDistributionsQuery(options?: Omit<Urql.UseQueryArgs<GetActiveDistributionsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetActiveDistributionsQuery, GetActiveDistributionsQueryVariables>({ query: GetActiveDistributionsDocument, ...options });
};
export const GetWithdrawalsDocument = gql`
    query getWithdrawals($page: Int!) {
  getWithdrawals(page: $page) {
    id
    organizationId
    amount
    status
    txHash
  }
}
    `;

export function useGetWithdrawalsQuery(options: Omit<Urql.UseQueryArgs<GetWithdrawalsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetWithdrawalsQuery, GetWithdrawalsQueryVariables>({ query: GetWithdrawalsDocument, ...options });
};
export const GetLogHistoryOfAdminDocument = gql`
    query getLogHistoryOfAdmin($page: Int!) {
  getLogHistoryOfAdmin(page: $page) {
    id
    username
    action
    date
  }
}
    `;

export function useGetLogHistoryOfAdminQuery(options: Omit<Urql.UseQueryArgs<GetLogHistoryOfAdminQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLogHistoryOfAdminQuery, GetLogHistoryOfAdminQueryVariables>({ query: GetLogHistoryOfAdminDocument, ...options });
};
export const GetDistributionDocument = gql`
    query getDistribution($id: Int!) {
  getDistribution(id: $id) {
    id
    amount
    organizationBump
    gas
    totalAmount
    createdBy
    approvedBy
    transactions {
      to
      invoiceId
      toName
      amount
    }
    createdAt
    processedAt
    mint
    status
    txHash
  }
}
    `;

export function useGetDistributionQuery(options: Omit<Urql.UseQueryArgs<GetDistributionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDistributionQuery, GetDistributionQueryVariables>({ query: GetDistributionDocument, ...options });
};
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;

export function useLogoutMutation() {
  return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument);
};
export const ApprovalTransactionDocument = gql`
    mutation approvalTransaction($id: Int!) {
  approvalTransaction(id: $id)
}
    `;

export function useApprovalTransactionMutation() {
  return Urql.useMutation<ApprovalTransactionMutation, ApprovalTransactionMutationVariables>(ApprovalTransactionDocument);
};
export const DistributionTransactionDocument = gql`
    mutation distributionTransaction($id: Int!) {
  distributionTransaction(id: $id)
}
    `;

export function useDistributionTransactionMutation() {
  return Urql.useMutation<DistributionTransactionMutation, DistributionTransactionMutationVariables>(DistributionTransactionDocument);
};
export const WithdrawlTransactionDocument = gql`
    mutation withdrawlTransaction($organizationId: String!, $mint: Int!) {
  withdrawlTransaction(organizationId: $organizationId, mint: $mint)
}
    `;

export function useWithdrawlTransactionMutation() {
  return Urql.useMutation<WithdrawlTransactionMutation, WithdrawlTransactionMutationVariables>(WithdrawlTransactionDocument);
};
export const CreateOrganizationDocument = gql`
    mutation createOrganization($name: String!, $feeBps: Int!, $bump: Int!, $ethWalletAddress: String!, $btcWalletAddress: String!) {
  createOrganization(
    name: $name
    feeBps: $feeBps
    bump: $bump
    ethWalletAddress: $ethWalletAddress
    btcWalletAddress: $btcWalletAddress
  ) {
    id
    name
    feeBps
    ethWalletAddress
    btcWalletAddress
    balance {
      credit
      onHold
      mint
      mintId
      availableForWithdrawal
    }
  }
}
    `;

export function useCreateOrganizationMutation() {
  return Urql.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument);
};
export const UpdateOrganizationNameDocument = gql`
    mutation updateOrganizationName($organizationId: String!, $name: String!) {
  updateOrganizationName(organizationId: $organizationId, name: $name) {
    id
    name
    feeBps
    ethWalletAddress
    btcWalletAddress
    balance {
      credit
      onHold
      mint
      mintId
      availableForWithdrawal
    }
  }
}
    `;

export function useUpdateOrganizationNameMutation() {
  return Urql.useMutation<UpdateOrganizationNameMutation, UpdateOrganizationNameMutationVariables>(UpdateOrganizationNameDocument);
};
export const UpdateOrganizationFeeBpsDocument = gql`
    mutation updateOrganizationFeeBps($organizationId: String!, $feeBps: Int!) {
  updateOrganizationFeeBps(organizationId: $organizationId, feeBps: $feeBps) {
    id
    name
    feeBps
    ethWalletAddress
    btcWalletAddress
    balance {
      credit
      onHold
      mint
      mintId
      availableForWithdrawal
    }
  }
}
    `;

export function useUpdateOrganizationFeeBpsMutation() {
  return Urql.useMutation<UpdateOrganizationFeeBpsMutation, UpdateOrganizationFeeBpsMutationVariables>(UpdateOrganizationFeeBpsDocument);
};
export const AddUserDocument = gql`
    mutation addUser($organizationId: String!, $username: String!, $email: String!, $role: String!) {
  addUser(
    organizationId: $organizationId
    username: $username
    email: $email
    role: $role
  ) {
    userId
    username
    email
    isActive
    role
  }
}
    `;

export function useAddUserMutation() {
  return Urql.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument);
};
export const UpdateUserRoleDocument = gql`
    mutation updateUserRole($userId: String!, $role: String!) {
  updateUserRole(userId: $userId, role: $role) {
    userId
    username
    email
    isActive
    role
  }
}
    `;

export function useUpdateUserRoleMutation() {
  return Urql.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument);
};
export const ToggleUserStatusDocument = gql`
    mutation toggleUserStatus($userId: String!) {
  toggleUserStatus(userId: $userId) {
    userId
    username
    email
    isActive
    role
  }
}
    `;

export function useToggleUserStatusMutation() {
  return Urql.useMutation<ToggleUserStatusMutation, ToggleUserStatusMutationVariables>(ToggleUserStatusDocument);
};
export const ResetPasswordDocument = gql`
    mutation resetPassword($userId: String!) {
  resetPassword(userId: $userId) {
    userId
    username
    email
    isActive
    role
  }
}
    `;

export function useResetPasswordMutation() {
  return Urql.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument);
};
export const CancelDistributionDocument = gql`
    mutation cancelDistribution($id: Int!) {
  cancelDistribution(id: $id)
}
    `;

export function useCancelDistributionMutation() {
  return Urql.useMutation<CancelDistributionMutation, CancelDistributionMutationVariables>(CancelDistributionDocument);
};


export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AdminLog: ResolverTypeWrapper<AdminLog>;
  Balance: ResolverTypeWrapper<Balance>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  DetailedDistribution: ResolverTypeWrapper<DetailedDistribution>;
  Distribution: ResolverTypeWrapper<Distribution>;
  DistributionTransaction: ResolverTypeWrapper<DistributionTransaction>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Mint: ResolverTypeWrapper<Mint>;
  Mutation: ResolverTypeWrapper<{}>;
  Organization: ResolverTypeWrapper<Organization>;
  Query: ResolverTypeWrapper<{}>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Transaction: ResolverTypeWrapper<Transaction>;
  TransactionType: TransactionType;
  User: ResolverTypeWrapper<User>;
  UserLog: ResolverTypeWrapper<UserLog>;
  Withdrawal: ResolverTypeWrapper<Withdrawal>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AdminLog: AdminLog;
  Balance: Balance;
  Boolean: Scalars['Boolean']['output'];
  DetailedDistribution: DetailedDistribution;
  Distribution: Distribution;
  DistributionTransaction: DistributionTransaction;
  Int: Scalars['Int']['output'];
  Mint: Mint;
  Mutation: {};
  Organization: Organization;
  Query: {};
  String: Scalars['String']['output'];
  Transaction: Transaction;
  User: User;
  UserLog: UserLog;
  Withdrawal: Withdrawal;
};

export type AdminLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminLog'] = ResolversParentTypes['AdminLog']> = {
  action?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BalanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Balance'] = ResolversParentTypes['Balance']> = {
  availableForWithdrawal?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  credit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mint?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mintId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  onHold?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DetailedDistributionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DetailedDistribution'] = ResolversParentTypes['DetailedDistribution']> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  approvedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gas?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mint?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationBump?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  processedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalAmount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transactions?: Resolver<Array<ResolversTypes['DistributionTransaction']>, ParentType, ContextType>;
  txHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistributionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Distribution'] = ResolversParentTypes['Distribution']> = {
  bump?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactions?: Resolver<Array<Maybe<ResolversTypes['DistributionTransaction']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistributionTransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DistributionTransaction'] = ResolversParentTypes['DistributionTransaction']> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  invoiceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  toName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MintResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mint'] = ResolversParentTypes['Mint']> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationAddUserArgs, 'email' | 'organizationId' | 'role' | 'username'>>;
  approvalTransaction?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationApprovalTransactionArgs, 'id'>>;
  cancelDistribution?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationCancelDistributionArgs, 'id'>>;
  createOrganization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType, RequireFields<MutationCreateOrganizationArgs, 'btcWalletAddress' | 'bump' | 'ethWalletAddress' | 'feeBps' | 'name'>>;
  distributionTransaction?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationDistributionTransactionArgs, 'id'>>;
  executeWithdrawal?: Resolver<ResolversTypes['Withdrawal'], ParentType, ContextType, RequireFields<MutationExecuteWithdrawalArgs, 'mint' | 'organizationId' | 'tx'>>;
  logout?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  resetPassword?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'userId'>>;
  toggleUserStatus?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationToggleUserStatusArgs, 'userId'>>;
  updateOrganizationFeeBps?: Resolver<ResolversTypes['Organization'], ParentType, ContextType, RequireFields<MutationUpdateOrganizationFeeBpsArgs, 'feeBps' | 'organizationId'>>;
  updateOrganizationName?: Resolver<ResolversTypes['Organization'], ParentType, ContextType, RequireFields<MutationUpdateOrganizationNameArgs, 'name' | 'organizationId'>>;
  updateUserRole?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUserRoleArgs, 'role' | 'userId'>>;
  withdrawlTransaction?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationWithdrawlTransactionArgs, 'mint' | 'organizationId'>>;
};

export type OrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization']> = {
  balance?: Resolver<Array<ResolversTypes['Balance']>, ParentType, ContextType>;
  btcWalletAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bump?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ethWalletAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  feeBps?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  getActiveDistributions?: Resolver<Array<ResolversTypes['Distribution']>, ParentType, ContextType>;
  getDistribution?: Resolver<ResolversTypes['DetailedDistribution'], ParentType, ContextType, RequireFields<QueryGetDistributionArgs, 'id'>>;
  getLargestBump?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  getLogHistoryOfAdmin?: Resolver<Array<ResolversTypes['AdminLog']>, ParentType, ContextType, RequireFields<QueryGetLogHistoryOfAdminArgs, 'page'>>;
  getLogHistoryOfOrganization?: Resolver<Array<ResolversTypes['UserLog']>, ParentType, ContextType, RequireFields<QueryGetLogHistoryOfOrganizationArgs, 'organizationId' | 'page'>>;
  getLogHistoryOfUser?: Resolver<Array<ResolversTypes['UserLog']>, ParentType, ContextType, RequireFields<QueryGetLogHistoryOfUserArgs, 'page' | 'userId'>>;
  getNonce?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  getOrganization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType, RequireFields<QueryGetOrganizationArgs, 'organizationId'>>;
  getOrganizations?: Resolver<Array<ResolversTypes['Organization']>, ParentType, ContextType, RequireFields<QueryGetOrganizationsArgs, 'page'>>;
  getTransactionsHistory?: Resolver<Array<ResolversTypes['Transaction']>, ParentType, ContextType, RequireFields<QueryGetTransactionsHistoryArgs, 'organizationId' | 'page'>>;
  getUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QueryGetUserArgs, 'userId'>>;
  getUsersOfOrganization?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryGetUsersOfOrganizationArgs, 'organizationId' | 'page'>>;
  getWithdrawals?: Resolver<Array<ResolversTypes['Withdrawal']>, ParentType, ContextType, RequireFields<QueryGetWithdrawalsArgs, 'page'>>;
};

export type TransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Transaction'] = ResolversParentTypes['Transaction']> = {
  availableForWithdrawal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditDelta?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isProcessed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  txHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TransactionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserLog'] = ResolversParentTypes['UserLog']> = {
  action?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  organizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WithdrawalResolvers<ContextType = any, ParentType extends ResolversParentTypes['Withdrawal'] = ResolversParentTypes['Withdrawal']> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  txHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AdminLog?: AdminLogResolvers<ContextType>;
  Balance?: BalanceResolvers<ContextType>;
  DetailedDistribution?: DetailedDistributionResolvers<ContextType>;
  Distribution?: DistributionResolvers<ContextType>;
  DistributionTransaction?: DistributionTransactionResolvers<ContextType>;
  Mint?: MintResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Organization?: OrganizationResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Transaction?: TransactionResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserLog?: UserLogResolvers<ContextType>;
  Withdrawal?: WithdrawalResolvers<ContextType>;
};

