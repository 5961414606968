import * as React from "react";

import { ArrowLeft, DollarSign, Users } from "lucide-react";
import { Button } from "../components/ui/button";

import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import {
  useApprovalTransactionMutation,
  useCancelDistributionMutation,
  useDistributionTransactionMutation,
  useGetDistributionQuery,
} from "../generated/graphql";
import { useEffect } from "react";
import { Toaster } from "../components/ui/toaster";
import { useToast } from "../components/ui/use-toast";
import { formatNumber } from "../lib/formatNumbers";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import {
  TransactionStatus,
  Transactor,
  useTransactor,
} from "../components/Transactor";
import { ethers } from "ethers";
import {
  signTransaction,
  broadcastTransaction,
  waitForTransaction,
} from "../lib/ethtransaction";
import Transport from "@ledgerhq/hw-transport";
import AppEth from "@ledgerhq/hw-app-eth";

export function Distribution() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { id } = useParams();
  // const [transactionIdAndBump, setTransactionIdAndBump] = React.useState<
  //   [number, number] | null
  // >(null);
  const [open, setOpen] = React.useState(false);
  const { transactionStatus, txHash, setTransactionStatus, setTxHash } =
    useTransactor();

  const [{ data, error }, refetchDistribution] = useGetDistributionQuery({
    variables: {
      id: id ? parseInt(id) : null || 0,
    },
    pause: !id,
  });

  useEffect(() => {
    if (error) {
      console.error(error);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  }, [error, toast]);
  const [{ error: distributionTransactionError }, getDistributionTransaction] =
    useDistributionTransactionMutation();

  const [{ error: approveTransactionError }, approvalTransaction] =
    useApprovalTransactionMutation();

  const [{ error: cancelDistributionError }, cancelDistribution] =
    useCancelDistributionMutation();

  // toast errors
  useEffect(() => {
    if (distributionTransactionError) {
      toast({
        title: "Error",
        description: distributionTransactionError.message,
        variant: "destructive",
      });
    }

    if (approveTransactionError) {
      toast({
        title: "Error",
        description: approveTransactionError.message,
        variant: "destructive",
      });
    }

    if (cancelDistributionError) {
      toast({
        title: "Error",
        description: cancelDistributionError.message,
        variant: "destructive",
      });
    }
  }, [
    approveTransactionError,
    cancelDistributionError,
    distributionTransactionError,
    toast,
  ]);

  const executeDistribution = async (
    _transport: Transport,
    id: number,
    bump: number
  ) => {
    let transport = _transport;
    let eth = new AppEth(transport);

    try {
      await eth.getAppConfiguration();
    } catch (e) {
      setTransactionStatus(TransactionStatus.OPENAPP);
      toast({
        title: "Error",
        description: (e as Error).message,
        variant: "destructive",
      });
      return;
    }
    const approvalData = await approvalTransaction({
      id: id,
    });

    const transactionBase64Approval = approvalData.data?.approvalTransaction;

    try {
      if (transactionBase64Approval) {
        const transactions = JSON.parse(
          Buffer.from(transactionBase64Approval, "base64").toString()
        );

        if (transactions.length > 0) {
          const tx = transactions[0];

          const provider = new ethers.JsonRpcProvider(
            process.env.REACT_APP_ETH_RPC_URL
          );

          setTransactionStatus(TransactionStatus.SIGN);
          const t = await signTransaction(provider, eth, bump, tx);

          setTransactionStatus(TransactionStatus.BROADCAST);
          const receipt = await broadcastTransaction(provider, t);

          setTransactionStatus(TransactionStatus.CONFIRMING);
          console.log(receipt);
          await waitForTransaction(provider, receipt);

          toast({
            title: "Transaction",
            description: `Approval Transaction executed, distribution will require signature`,
            variant: "default",
          });
        }
      }

      const distributionData = await getDistributionTransaction({
        id: id,
      });
      const transactionBase64 = distributionData.data?.distributionTransaction;
      if (transactionBase64) {
        const transactions = JSON.parse(
          Buffer.from(transactionBase64, "base64").toString()
        );

        const provider = new ethers.JsonRpcProvider(
          process.env.REACT_APP_ETH_RPC_URL
        );

        for (let i = 0; i < transactions.length; i++) {
          const address = transactions[i];

          setTransactionStatus(TransactionStatus.SIGN);
          const t = await signTransaction(provider, eth, bump, address);

          setTransactionStatus(TransactionStatus.BROADCAST);
          const receipt = await broadcastTransaction(provider, t);

          setTransactionStatus(TransactionStatus.CONFIRMING);
          console.log(receipt);
          const txReceipt = await waitForTransaction(provider, receipt);

          setTransactionStatus(TransactionStatus.SUCCESS);
          setTxHash(txReceipt.hash);
        }
      }
    } catch (e) {
      setOpen(false);
      setTransactionStatus(TransactionStatus.CONNECT);
      toast({
        title: "Error",
        description: (e as Error).message,
        variant: "destructive",
      });
    }
  };

  const distribution = data?.getDistribution;
  const bump = distribution?.organizationBump ?? null;
  const txId = distribution?.id ?? null;

  return (
    <div className="flex min-h-screen w-full flex-col">
      <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
        <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
          <Button variant="outline" size="icon" onClick={() => navigate("/")}>
            <ArrowLeft className="h-4 w-4" />
          </Button>
        </div>
      </header>
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        {distribution != null && (
          <>
            <div className="grid gap-2 md:grid-cols-1 md:gap-8 lg:grid-cols-2">
              {/* <div className="grid gap-2"> */}
              <div>
                <div className="text-lg">ID</div>
                <h1 className="text-2xl font-semibold">D{distribution.id}</h1>
              </div>
              <div>
                <div className="text-lg">Status</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div
                      style={{
                        height: 20,
                        width: 20,
                        borderRadius: "50%",
                        marginRight: 10,
                        background:
                          distribution.status === "CANCELLED"
                            ? "grey"
                            : distribution.status === "PROCESSED"
                            ? "green"
                            : "yellow",
                      }}
                    />
                    <div className="text-2xl font-semibold">
                      {distribution.status}
                    </div>
                  </div>
                  {distribution.status === "APPROVED" && (
                    <>
                      <Button
                        variant="outline"
                        className="bg-black text-white"
                        onClick={async () => {
                          await cancelDistribution({
                            id: distribution.id,
                          });
                          refetchDistribution();
                        }}
                      >
                        CANCEL
                      </Button>
                      <Button
                        variant="outline"
                        className="bg-black text-white"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        EXECUTE
                      </Button>
                    </>
                  )}
                  {/* </div> */}
                </div>
              </div>
              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Payouts
                  </CardTitle>
                  <Users className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">
                    {distribution.transactions.length}
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Distributed
                  </CardTitle>
                  <CardTitle className="text-sm font-medium">
                    Gas & Processing fee
                  </CardTitle>
                  <CardTitle className="text-sm font-medium">
                    Total Amount
                  </CardTitle>
                  <DollarSign className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="flex justify-between pb-2">
                    <div className="text-2xl font-bold">
                      ${formatNumber(distribution.amount.toString())}
                    </div>
                    <div className="text-lg font-bold">
                      ${distribution.gas ? distribution.gas.toString() : "-"}
                    </div>
                    <div className="text-lg font-bold">
                      $
                      {distribution.totalAmount
                        ? formatNumber(distribution.totalAmount.toString())
                        : "-"}
                    </div>
                    <div></div>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Currency
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-lg font-bold">{distribution.mint}</div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">Info</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex justify-between pb-2">
                    <div>
                      <div className="text-lg font-bold">
                        Created By: {distribution.createdBy}
                      </div>
                      <div className="text-lg font-bold">
                        Approved By:{" "}
                        {distribution.approvedBy || "Not Approved Yet"}
                      </div>
                    </div>
                    <div>
                      <div className="text-lg font-bold">
                        Created At:{" "}
                        {new Date(distribution.createdAt).toLocaleDateString()}
                      </div>
                      <div className="text-lg font-bold">
                        Processed At:{" "}
                        {distribution.processedAt
                          ? new Date(
                              distribution.processedAt
                            ).toLocaleDateString()
                          : "Not Processed"}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {distribution.txHash && (
                <Card>
                  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">
                      Transaction Hash
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div
                      className="text-lg font-bold cursor-pointer"
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_ETH_EXPLORER_URL}${distribution.txHash}`,
                          "_blank"
                        );
                      }}
                    >
                      {distribution.txHash.slice(0, 10)}...
                    </div>
                  </CardContent>
                </Card>
              )}
            </div>

            <Card>
              <CardHeader className="flex flex-row items-center justify-between">
                <div className="grid gap-2">
                  <CardTitle> Detailed Distribution</CardTitle>
                </div>
                {distribution.processedAt != null && (
                  <Button
                    variant="outline"
                    size="icon"
                    className="bg-black text-white px-6"
                    onClick={() => {
                      const csv = distribution.transactions.map((t) => {
                        return {
                          referenceID: "D" + distribution.id,
                          invoiceId: t.invoiceId,
                          name: t.toName,
                          wallet: t.to,
                          amount: formatNumber(t.amount),
                          currency: distribution.mint,
                          datePaid: new Date(
                            distribution.processedAt!
                          ).toLocaleDateString(),
                          txHash: distribution.txHash,
                        };
                      });

                      // Parse the data into CSV
                      const parsed = csv.map((row) =>
                        Object.values(row)
                          .map((val) => `"${val}"`)
                          .join(",")
                      );

                      parsed.unshift(
                        Object.keys(csv[0])
                          .map((key) => `"${key}"`)
                          .join(",")
                      );

                      const csvData = new Blob([parsed.join("\n")], {
                        type: "text/csv;charset=utf-8",
                      });
                      const csvUrl = URL.createObjectURL(csvData);
                      const a = document.createElement("a");
                      a.href = csvUrl;
                      a.download = `distribution-${distribution.id}.csv`;
                      a.click();
                      URL.revokeObjectURL(csvUrl);
                    }}
                  >
                    CSV
                  </Button>
                )}
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Invoice ID</TableHead>
                      <TableHead>Name</TableHead>
                      <TableHead>Wallet</TableHead>
                      <TableHead>Amount</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {distribution.transactions?.map((d, idx) => (
                      <TableRow key={idx}>
                        <TableCell>{d.invoiceId}</TableCell>
                        <TableCell>{d.toName}</TableCell>
                        <TableCell>{d.to}</TableCell>
                        <TableCell>${formatNumber(d.amount)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </>
        )}
      </main>
      <Transactor
        title={`Execute Distribution ${id}`}
        open={open}
        transactionStatus={transactionStatus}
        setTransactionStatus={setTransactionStatus}
        setTxHash={setTxHash}
        cleanUp={() => {
          setOpen(false);
        }}
        executeTransaction={async (transport) => {
          try {
            if (txId == null || bump == null) {
              throw new Error("Invalid transaction id or bump");
            }
            await executeDistribution(transport, txId, bump);
          } catch (e) {
            console.error(e);
            toast({
              title: "Error",
              description: (e as Error).message,
              variant: "destructive",
            });
          }
        }}
        txHash={txHash}
      />
      <Toaster />
    </div>
  );
}
