import TransportWebBLE from "@ledgerhq/hw-transport-web-ble";
import TransportWebUSB from "@ledgerhq/hw-transport-webusb";
import Transport from "@ledgerhq/hw-transport";
import AppEth from "@ledgerhq/hw-app-eth";
// import { useEffect, useState } from "react";
import React, { useEffect, useState } from "react";
import { useToast } from "./ui/use-toast";

// window.Buffer = window.Buffer || require("buffer").Buffer;

// interface LedgerProps {
//   //   sendTransaction: (from: string, tx: string) => void;
//   bump: number;
// }

// export function Ledger(props: LedgerProps) {
//   //   const { sendTransaction } = props;
//   const [transport, setTransport] = useState<Transport | null>(null);

//   const createBLE = async () => {
//     const transport = await TransportWebBLE.create();
//     setTransport(transport);
//   };

//   const createUSB = async () => {
//     const transport = await TransportWebUSB.create();
//     setTransport(transport);
//   };

//   useEffect(() => {
//     if (transport) {
//       const eth = new AppEth(transport);
//       eth.getAddress(`44'/60'/0'/0/${props.bump}`).then((result) => {
//         console.log(result);
//       });
//     }
//   }, [props.bump, transport]);

//   return (
//     <div>
//       <h1>Ledger</h1>
//       {transport ? (
//         <button
//           onClick={() => {
//             // sendTransaction("0x1234", "0x5678");
//           }}
//         >
//           Send Transaction
//         </button>
//       ) : (
//         <div>
//           <button onClick={createBLE}>Create BLE</button>
//           <button onClick={createUSB}>Create USB</button>
//         </div>
//       )}
//     </div>
//   );
// }

interface LedgerContextType {
  transport: Transport | null;
  eth: AppEth | null;
  setTransport: React.Dispatch<React.SetStateAction<Transport | null>>;
  createBLE: () => void;
  createUSB: () => void;
  sendTransaction: (bump: number, tx: string) => Promise<string | undefined>;
}

let LedgerContext = React.createContext<LedgerContextType>(null!);

export function LedgerProvider({ children }: { children: React.ReactNode }) {
  const { toast } = useToast();
  const [transport, setTransport] = useState<Transport | null>(null);
  const [eth, setEth] = useState<AppEth | null>(null);

  const createBLE = async () => {
    try {
      const transport = await TransportWebBLE.create();
      setTransport(transport);
      toast({
        title: "Success",
        description: "Ledger connected",
      });
    } catch (e) {
      toast({
        title: "Error",
        description: "Ledger not connected",
        variant: "destructive",
      });
      console.log(e);
    }
  };

  const createUSB = async () => {
    try {
      const transport = await TransportWebUSB.create();
      setTransport(transport);
      toast({
        title: "Success",
        description: "Ledger connected",
      });
    } catch (e) {
      console.log(e);
      toast({
        title: "Error",
        description: "Ledger not connected",
        variant: "destructive",
      });
    }
  };

  const sendTransaction = async (bump: number, tx: string) => {
    if (transport) {
      const eth = new AppEth(transport);
      const transaction = await eth.signTransaction(`44'/60'/0'/0/${bump}`, tx);
      return Buffer.from(JSON.stringify(transaction)).toString("base64");
    }
  };

  useEffect(() => {
    try {
      if (transport) {
        const eth = new AppEth(transport);
        setEth(eth);
      }
    } catch (e) {
      console.log(e);
      toast({
        title: "Error",
        description: "Ledger not connected",
        variant: "destructive",
      });
    }
  }, [toast, transport]);

  let value = {
    transport,
    eth,
    setTransport,
    createBLE,
    createUSB,
    sendTransaction,
  };

  return (
    <LedgerContext.Provider value={value}>{children}</LedgerContext.Provider>
  );
}

export function useLedger() {
  return React.useContext(LedgerContext);
}
