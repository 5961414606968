import { useEffect, useState } from "react";
import { Navbar } from "../components/Navbar";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../components/ui/card";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "../components/ui/table";
import {
  useGetLogHistoryOfAdminQuery,
  useGetWithdrawalsQuery,
} from "../generated/graphql";
import { formatNumber } from "../lib/formatNumbers";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../components/ui/pagination";
import { useToast } from "../components/ui/use-toast";
import { Toaster } from "../components/ui/toaster";

export function Admin() {
  const { toast } = useToast();
  const [logsPage, setLogsPage] = useState(1);
  const [withdrawalsPage, setWithdrawalsPage] = useState(1);
  const [{ data: logsData, error: logsError }] = useGetLogHistoryOfAdminQuery({
    variables: { page: logsPage },
  });
  const [{ data: withdrawlData, error: withdrawlError }] =
    useGetWithdrawalsQuery({
      variables: { page: withdrawalsPage },
    });

  const logs = logsData?.getLogHistoryOfAdmin;
  const withdrawls = withdrawlData?.getWithdrawals;

  useEffect(() => {
    if (logsError) {
      toast({
        title: "Error",
        description: logsError.message,
        variant: "destructive",
      });
    }

    if (withdrawlError) {
      toast({
        title: "Error",
        description: withdrawlError.message,
        variant: "destructive",
      });
    }
  }, [logsError, toast, withdrawlError]);

  return (
    <div className="flex min-h-screen w-full flex-col">
      <Navbar />
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <Card className="xl:col-span-2">
          <CardHeader className="flex flex-row items-center">
            <div className="grid gap-2">
              <CardTitle>Withdrawls</CardTitle>
            </div>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>id</TableHead>
                  <TableHead>OrganizationId</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>amount</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {withdrawls?.map((log, idx) => (
                  <TableRow
                    key={idx}
                    onClick={() => {
                      // open in new tab
                      if (!log.txHash) return;
                      window.open(
                        `${process.env.REACT_APP_ETH_EXPLORER_URL}${log.txHash}`,
                        "_blank"
                      );
                    }}
                  >
                    <TableCell>W{log.id}</TableCell>
                    <TableCell>{log.organizationId}</TableCell>
                    <TableCell>{log.status}</TableCell>
                    <TableCell>{formatNumber(log.amount)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() => {
                      setWithdrawalsPage(withdrawalsPage - 1);
                    }}
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink>{withdrawalsPage}</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationNext
                    onClick={() => {
                      setWithdrawalsPage(withdrawalsPage + 1);
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </CardContent>
        </Card>
        <Card className="xl:col-span-2">
          <CardHeader className="flex flex-row items-center">
            <div className="grid gap-2">
              <CardTitle>Logs</CardTitle>
            </div>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>id</TableHead>
                  <TableHead>Username</TableHead>
                  <TableHead>Action</TableHead>
                  <TableHead>Date</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {logs?.map((log, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{log.id}</TableCell>
                    <TableCell>{log.username}</TableCell>
                    <TableCell>{log.action}</TableCell>
                    <TableCell>{log.date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() => {
                      setLogsPage(logsPage - 1);
                    }}
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink>{logsPage}</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationNext
                    onClick={() => {
                      setLogsPage(logsPage + 1);
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </CardContent>
        </Card>
      </main>
      <Toaster />
    </div>
  );
}
