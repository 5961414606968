import Eth, { ledgerService } from "@ledgerhq/hw-app-eth";
import { ethers, TransactionReceipt, Transaction, Signature } from "ethers";
import { Toast, ToasterToast } from "../components/ui/use-toast";

export const signTransaction = async (
  provider: ethers.JsonRpcProvider,
  eth: Eth | null,
  bump: number,
  tx: string
): Promise<ethers.Transaction> => {
  const trx = Transaction.from("0x" + tx);
  const path = `44'/60'/0'/0/${bump}`;
  const address = (await eth?.getAddress(path))?.address ?? "";
  trx.nonce = await provider.getTransactionCount(address);

  const resolution = await ledgerService.resolveTransaction(
    trx.unsignedSerialized.slice(2),
    {},
    {}
  );
  const signed = await eth?.signTransaction(
    path,
    trx.unsignedSerialized.slice(2),
    resolution
  );

  if (!signed) throw new Error("Invalid signature");

  const signature = Signature.from({
    v: signed.v,
    r: "0x" + signed.r,
    s: "0x" + signed.s,
  });

  trx.signature = signature;
  return trx;
};

export const broadcastTransaction = async (
  provider: ethers.JsonRpcProvider,
  trx: ethers.Transaction
): Promise<string> => {
  const rpcReq = provider.getRpcRequest({
    method: "broadcastTransaction",
    signedTransaction: trx.serialized ?? "",
  });
  if (!rpcReq) throw new Error("Invalid rpc request");

  const receipt = await provider.send(rpcReq.method, rpcReq.args);

  if (receipt == null) {
    throw new Error("Transaction failed");
  }

  return receipt;
};

export const waitForTransaction = async (
  provider: ethers.JsonRpcProvider,
  receipt: string
): Promise<TransactionReceipt> => {
  const txRecipt = await provider.waitForTransaction(receipt);

  if (!txRecipt) {
    throw new Error("Transaction failed");
  }

  return txRecipt;
};

export const executeTransaction = async (
  provider: ethers.JsonRpcProvider,
  eth: Eth | null,
  bump: number,
  tx: string,
  toast: ({ ...props }: Toast) => {
    id: string;
    dismiss: () => void;
    update: (props: ToasterToast) => void;
  }
): Promise<TransactionReceipt | null> => {
  const trx = Transaction.from("0x" + tx);
  const path = `44'/60'/0'/0/${bump}`;
  const address = (await eth?.getAddress(path))?.address ?? "";
  trx.nonce = await provider.getTransactionCount(address);

  toast({
    title: "Transaction",
    description: `Signing transaction ${trx.hash} look at your ledger`,
    variant: "default",
  });
  const resolution = await ledgerService.resolveTransaction(
    trx.unsignedSerialized.slice(2),
    {},
    {}
  );
  const signed = await eth?.signTransaction(
    path,
    trx.unsignedSerialized.slice(2),
    resolution
  );

  if (!signed) throw new Error("Invalid signature");

  const signature = Signature.from({
    v: signed.v,
    r: "0x" + signed.r,
    s: "0x" + signed.s,
  });

  trx.signature = signature;

  toast({
    title: "Transaction",
    description: `Broadcasting transaction ${trx.hash}`,
    variant: "default",
  });

  const rpcReq = provider.getRpcRequest({
    method: "broadcastTransaction",
    signedTransaction: trx.serialized ?? "",
  });
  if (!rpcReq) throw new Error("Invalid rpc request");

  const receipt = await provider.send(rpcReq.method, rpcReq.args);

  if (receipt === null) {
    throw new Error("Transaction failed");
  }

  toast({
    title: "Transaction",
    description: `Transaction ${receipt} broadcasting awaiting confirmation`,
    variant: "default",
  });

  // wait for transaction to be mined
  const txRecipt = await provider.waitForTransaction(receipt);

  if (!txRecipt) {
    throw new Error("Transaction failed");
  }

  toast({
    title: "Transaction",
    description: `Transaction ${txRecipt.hash} executed`,
    variant: "default",
  });

  return txRecipt;
};
