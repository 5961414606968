import React, { useEffect } from "react";

import { Navigate, useLocation } from "react-router-dom";
import { Auth } from "./auth";

interface AuthContextType {
  auth: {
    token: string;
    refreshToken: string;
  } | null;
  setAuth: React.Dispatch<
    React.SetStateAction<{
      token: string;
      refreshToken: string;
    } | null>
  >;
  login: (username: string, password: string) => Promise<boolean>;
  signout: () => void;
  resetPassword: (password: string, token: string) => Promise<boolean>;
}

let AuthContext = React.createContext<AuthContextType>(null!);

const AuthApi = new Auth();

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [auth, setAuth] = React.useState<{
    token: string;
    refreshToken: string;
  } | null>(null);

  useEffect(() => {
    // Try to restore the auth state from local storage when the app loads
    let auth = localStorage.getItem("auth");
    if (auth) {
      setAuth(JSON.parse(auth));
    }
  }, []);

  useEffect(() => {
    // Save the auth state to local storage whenever it changes
    if (auth === null) {
      localStorage.removeItem("auth");
    }
    if (auth) {
      localStorage.setItem("auth", JSON.stringify(auth));
    }
  }, [auth]);

  let login = async (username: string, password: string) => {
    try {
      const auth = await AuthApi.login(username, password);
      setAuth(auth);
      return true;
    } catch {
      return false;
    }
  };

  let signout = () => {
    setAuth(null);
  };

  let resetPassword = async (password: string, token: string) => {
    try {
      await AuthApi.resetPassword(password, token);
      return true;
    } catch {
      return false;
    }
  };

  let value = { auth, setAuth, login, signout, resetPassword };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
