import axios, { type AxiosInstance } from "axios";
export class Auth {
  private readonly axiosInstance: AxiosInstance;

  constructor() {
    console.log(process.env.REACT_APP_API_URL);
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Accept: "application/json",
      },
    });

    this.axiosInstance.interceptors.request.use(
      (config) => {
        console.debug(`LOGIN request: ${config.method} ${config.url}`);
        return config;
      },
      (error) => {
        console.error(`LOGIN request: ${error.message}`, error.toJSON());
        const errorMessage =
          error.response?.data?.message != null
            ? error.response.data.message
            : error.message ?? "Unknown error occurred";

        // Throw the error message instead of the entire error object
        return Promise.reject(new Error(errorMessage));
      }
    );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        console.debug(
          `LOGIN response: ${response.status} ${response.statusText}`
        );
        return response;
      },
      (error) => {
        console.error(`LOGIN response: ${error.message}`, error.toJSON());
        const errorMessage =
          error.response?.data?.message != null
            ? error.response.data.message
            : error.message ?? "Unknown error occurred";

        // Throw the error message instead of the entire error object
        return Promise.reject(new Error(errorMessage));
      }
    );
  }

  async login(
    username: string,
    password: string
  ): Promise<{ token: string; refreshToken: string }> {
    const response = await this.axiosInstance.post<{
      token: string;
      refreshToken: string;
    }>("/login", { username, password });
    return response.data;
  }

  isJwtStale(jwt: string): boolean {
    const payload = JSON.parse(atob(jwt.split(".")[1]));
    const expiration = payload.exp * 1000;
    return Date.now() >= expiration;
  }

  async refresh(
    refreshToken: string
  ): Promise<{ token: string; refreshToken: string }> {
    const response = await this.axiosInstance.post<{
      token: string;
      refreshToken: string;
    }>("/refreshtoken", { refreshToken });
    return response.data;
  }

  async resetPassword(password: string, token: string): Promise<void> {
    await this.axiosInstance.post("/resetpassword", { password, token });
  }
}
