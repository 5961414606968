import { CircleUser, Menu, Package2 } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Sheet, SheetContent, SheetTrigger } from "./ui/sheet";
import { useLogoutMutation } from "../generated/graphql";
import { useEffect } from "react";
import { useAuth } from "../AuthProvider";
import { useToast } from "./ui/use-toast";

const renderSelectedClassName = (pathname: string, href: string) => {
  if (pathname === href) {
    return "text-foreground transition-colors ";
  }
  return "text-muted-foreground transition-colors hover:text-foreground";
};

export function Navbar() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();
  const [{ data: logoutData, error: logoutError }, logout] =
    useLogoutMutation();

  // on logout
  useEffect(() => {
    const logout = async () => {
      if (logoutData) {
        toast({
          title: "Success",
          description: "Logged out successfully",
          variant: "default",
        });
        await auth.signout();
      }
    };
    logout();
  }, [logoutData, toast, navigate, auth]);

  // toast errors
  useEffect(() => {
    if (logoutError) {
      toast({
        title: "Error",
        description: logoutError.message,
        variant: "destructive",
      });
    }
  }, [logoutError, toast]);
  return (
    <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
      <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
        <Link
          to="/"
          className={`flex items-center gap-2 text-lg font-semibold md:text-base`}
        >
          <Package2 className="h-6 w-6" />
        </Link>
        <Link
          to="/"
          className={renderSelectedClassName(location.pathname, "/")}
        >
          Dashboard
        </Link>
        <Link
          to="/organizations"
          className={renderSelectedClassName(
            location.pathname,
            "/organizations"
          )}
        >
          Organizations
        </Link>
        <Link
          to="/admin"
          className={renderSelectedClassName(location.pathname, "/admin")}
        >
          Admin
        </Link>
      </nav>
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="shrink-0 md:hidden">
            <Menu className="h-5 w-5" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left">
          <nav className="grid gap-6 text-lg font-medium">
            <Link
              to="/"
              className="flex items-center gap-2 text-lg font-semibold"
            >
              <Package2 className="h-6 w-6" />
            </Link>
            <Link
              to="/"
              className={renderSelectedClassName(location.pathname, "/")}
            >
              Dashboard
            </Link>
            <Link
              to="/organizations"
              className={renderSelectedClassName(
                location.pathname,
                "/organizations"
              )}
            >
              Organizations
            </Link>
            <Link
              to="admin"
              className={renderSelectedClassName(location.pathname, "/admin")}
            >
              Admin
            </Link>
          </nav>
        </SheetContent>
      </Sheet>
      <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
        <form className="ml-auto flex-1 sm:flex-initial"></form>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="secondary" size="icon" className="rounded-full">
              <CircleUser className="h-5 w-5" />
              <span className="sr-only">Toggle user menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={async () => {
                await logout({});
              }}
            >
              Logout
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
}
