import { useNavigate, useParams } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";

import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import {
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetFooter,
  SheetClose,
  Sheet,
} from "../components/ui/sheet";
import {
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Table,
} from "../components/ui/table";
import { formatNumber } from "../lib/formatNumbers";
import {
  TransactionType,
  useAddUserMutation,
  useWithdrawlTransactionMutation,
  useGetLogHistoryOfOrganizationQuery,
  useGetOrganizationQuery,
  useGetTransactionsHistoryQuery,
  useGetUsersOfOrganizationQuery,
  useUpdateOrganizationFeeBpsMutation,
  useUpdateOrganizationNameMutation,
} from "../generated/graphql";
import { useEffect, useState } from "react";
import { Badge } from "../components/ui/badge";
import { Label } from "../components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../components/ui/pagination";
import {
  broadcastTransaction,
  signTransaction,
  waitForTransaction,
} from "../lib/ethtransaction";
import { ethers } from "ethers";
import { useToast } from "../components/ui/use-toast";
import Transport from "@ledgerhq/hw-transport";
import { Toaster } from "../components/ui/toaster";
import AppEth from "@ledgerhq/hw-app-eth";
import {
  TransactionStatus,
  Transactor,
  useTransactor,
} from "../components/Transactor";
import { formatDate } from "../lib/formatDate";

export function Organization() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { toast } = useToast();
  const [transactionPage, setTransactionPage] = useState(1);
  const [userPage, setUserPage] = useState(1);
  const [logPage, setLogPage] = useState(1);
  const [name, setName] = useState("");
  const [feeBps, setFeeBps] = useState(0);

  const [username, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [withdrawMint, setWithdrawMint] = useState<number | null>(null);
  const { transactionStatus, txHash, setTransactionStatus, setTxHash } =
    useTransactor();

  const [{ error: withdrawlTransactionError }, withdrawlTransaction] =
    useWithdrawlTransactionMutation();
  const [{ data: addUserData, error: addUserError }, addUser] =
    useAddUserMutation();

  const [
    { data: organizationData, error: organizationError },
    refetchOrganization,
  ] = useGetOrganizationQuery({
    variables: { organizationId: id ?? "" },
    pause: !id,
  });
  const [{ data: transactionsData, error: transactionsError }] =
    useGetTransactionsHistoryQuery({
      variables: { organizationId: id ?? "", page: transactionPage },
      pause: !id,
    });
  const [{ data: usersData, error: usersError }, refetchUsers] =
    useGetUsersOfOrganizationQuery({
      variables: { organizationId: id ?? "", page: userPage },
      pause: !id,
    });
  const [{ data: logsData, error: logsError }] =
    useGetLogHistoryOfOrganizationQuery({
      variables: { organizationId: id ?? "", page: logPage },
      pause: !id,
    });
  const [{ data: updateNameData, error: updateNameError }, updateName] =
    useUpdateOrganizationNameMutation();
  const [{ data: updateFeeBpsData, error: updateFeeBpsError }, upateFeeBps] =
    useUpdateOrganizationFeeBpsMutation();

  const organization = organizationData?.getOrganization;
  const transactions = transactionsData?.getTransactionsHistory;
  const users = usersData?.getUsersOfOrganization;
  const logs = logsData?.getLogHistoryOfOrganization;

  useEffect(() => {
    if (organizationError) {
      toast({
        title: "Error",
        description: organizationError.message,
        variant: "destructive",
      });
    }

    if (transactionsError) {
      toast({
        title: "Error",
        description: transactionsError.message,
        variant: "destructive",
      });
    }

    if (usersError) {
      toast({
        title: "Error",
        description: usersError.message,
        variant: "destructive",
      });
    }

    if (logsError) {
      toast({
        title: "Error",
        description: logsError.message,
        variant: "destructive",
      });
    }

    if (withdrawlTransactionError) {
      toast({
        title: "Error",
        description: withdrawlTransactionError.message,
        variant: "destructive",
      });
    }

    if (addUserError) {
      toast({
        title: "Error",
        description: addUserError.message,
        variant: "destructive",
      });
    }

    if (updateNameError) {
      toast({
        title: "Error",
        description: updateNameError.message,
        variant: "destructive",
      });
    }

    if (updateFeeBpsError) {
      toast({
        title: "Error",
        description: updateFeeBpsError.message,
        variant: "destructive",
      });
    }
  }, [
    organizationError,
    transactionsError,
    usersError,
    logsError,
    withdrawlTransactionError,
    addUserError,
    updateNameError,
    updateFeeBpsError,
    toast,
  ]);

  useEffect(() => {
    if (addUserData) {
      setUser("");
      setEmail("");
      setRole("");
      refetchUsers();
    }

    if (updateFeeBpsData || updateNameData) {
      refetchOrganization();
    }
  }, [
    addUserData,
    refetchOrganization,
    refetchUsers,
    updateFeeBpsData,
    updateNameData,
  ]);

  const executeWithdrawl = async (_transport: Transport, mintId: number) => {
    let transport = _transport;
    if (!organization) return;
    let eth = new AppEth(transport);

    try {
      await eth.getAppConfiguration();
    } catch (e) {
      setTransactionStatus(TransactionStatus.OPENAPP);
      toast({
        title: "Error",
        description: (e as Error).message,
        variant: "destructive",
      });
      return;
    }

    try {
      const withdrawData = await withdrawlTransaction({
        organizationId: organization.id,
        mint: mintId,
      });
      const transactionBase64 = withdrawData.data?.withdrawlTransaction;
      console.log(transactionBase64);
      if (transactionBase64) {
        const transaction = Buffer.from(transactionBase64, "base64").toString();
        console.log(transaction);
        const provider = new ethers.JsonRpcProvider(
          process.env.REACT_APP_ETH_RPC_URL
        );
        setTransactionStatus(TransactionStatus.SIGN);
        const tx = await signTransaction(
          provider,
          eth,
          organization.bump,
          transaction
        );
        console.log(tx);
        setTransactionStatus(TransactionStatus.BROADCAST);
        const receipt = await broadcastTransaction(provider, tx);
        console.log(receipt);

        setTransactionStatus(TransactionStatus.CONFIRMING);
        console.log(receipt);
        const txReceipt = await waitForTransaction(provider, receipt);
        console.log(txReceipt);
        setTransactionStatus(TransactionStatus.SUCCESS);
        setTxHash(txReceipt.hash);
      }
    } catch (e) {
      setWithdrawMint(null);
      setTransactionStatus(TransactionStatus.CONNECT);
      toast({
        title: "Error",
        description: (e as Error).message,
        variant: "destructive",
      });
    }
  };

  return (
    <div className="flex min-h-screen w-full flex-col">
      <Navbar />
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Organization</CardTitle>
            {/* <DollarSign className="h-4 w-4 text-muted-foreground" /> */}
            <Sheet>
              <SheetTrigger asChild>
                <Button variant="outline">Edit</Button>
              </SheetTrigger>
              <SheetContent side="bottom">
                <SheetHeader>
                  <SheetTitle>Edit Organization</SheetTitle>
                </SheetHeader>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="name" className="text-right">
                      Name
                    </Label>
                    <Input
                      id="name"
                      placeholder="Organization Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      className="col-span-3"
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="feeBps" className="text-right">
                      FeeBps
                    </Label>
                    <Input
                      id="feeBps"
                      type="number"
                      placeholder="0"
                      value={feeBps}
                      onChange={(e) => {
                        setFeeBps(parseInt(e.target.value));
                      }}
                      className="col-span-3"
                    />
                  </div>
                </div>
                <SheetFooter>
                  <SheetClose asChild>
                    <Button
                      type="submit"
                      onClick={async () => {
                        if (!id) return;
                        if (name !== organization?.name) {
                          await updateName({
                            name,
                            organizationId: id,
                          });
                        }
                        if (feeBps !== organization?.feeBps) {
                          await upateFeeBps({
                            feeBps,
                            organizationId: id,
                          });
                        }
                        setName("");
                        setFeeBps(0);
                      }}
                    >
                      Save changes
                    </Button>
                  </SheetClose>
                </SheetFooter>
              </SheetContent>
            </Sheet>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold"></div>

            <p className="text-xs text-muted-foreground">
              Name: {organization?.name}
            </p>
            <p className="text-xs text-muted-foreground">
              FeeBps: {organization?.feeBps}
            </p>

            <p className="text-xs text-muted-foreground">
              ETH Address: {organization?.ethWalletAddress}
            </p>
          </CardContent>
        </Card>

        <Card className="xl:col-span-2">
          <CardHeader className="flex flex-row items-center">
            <div className="grid gap-2">
              <CardTitle>{organization?.name}: Balances</CardTitle>
            </div>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Mint</TableHead>
                  <TableHead>Credit</TableHead>
                  <TableHead>On hold</TableHead>
                  <TableHead>Available</TableHead>
                  <TableHead className="text-right">Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {organization?.balance?.map((user, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{user.mint}</TableCell>
                    <TableCell>{formatNumber(user.credit)}</TableCell>
                    <TableCell>{formatNumber(user.onHold)}</TableCell>
                    <TableCell>
                      {formatNumber(user.availableForWithdrawal)}
                    </TableCell>
                    <TableCell className="text-right">
                      <Button
                        className="bg-black text-white"
                        disabled={user.availableForWithdrawal === "0"}
                        onClick={async () => {
                          if (user.availableForWithdrawal === "0") return;
                          setWithdrawMint(user.mintId);
                        }}
                      >
                        Withdraw
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>

        <Card className="xl:col-span-2">
          <CardHeader className="flex flex-row items-center">
            <div className="grid gap-2">
              <CardTitle>{organization?.name}: Transactions</CardTitle>
            </div>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>id</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Date</TableHead>
                  <TableHead>Withdrawal</TableHead>
                  <TableHead>Deposit</TableHead>
                  <TableHead>Balance</TableHead>
                  <TableHead className="text-right">Available</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {transactions?.map((transaction, idx) => (
                  <TableRow
                    key={idx}
                    onClick={() => {
                      if (transaction.type === TransactionType.Distribution) {
                        navigate(`/distribution/${transaction.id}`);
                        return;
                      }
                      // open in new tab
                      if (!transaction.txHash) return;
                      window.open(
                        `${process.env.REACT_APP_ETH_EXPLORER_URL}${transaction.txHash}`,
                        "_blank"
                      );
                    }}
                  >
                    <TableCell>
                      {(() => {
                        switch (transaction.type) {
                          case TransactionType.Distribution:
                            return `D`;
                          case TransactionType.Topup:
                            return `T`;
                          default:
                            return `W`;
                        }
                      })()}
                      {transaction.id}
                    </TableCell>
                    <TableCell>
                      <Badge className="text-xs" variant="outline">
                        {transaction.type}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      <Badge className="text-xs" variant="outline">
                        <div
                          style={{
                            height: 10,
                            width: 10,
                            borderRadius: "50%",
                            marginRight: 4,
                            background:
                              transaction.status === "CANCELLED"
                                ? "grey"
                                : transaction.status === "PROCESSED"
                                ? "green"
                                : "yellow",
                          }}
                        />
                        {transaction.status === "CANCELLED"
                          ? "Canceled"
                          : transaction.isProcessed
                          ? "Processed"
                          : "Pending"}
                      </Badge>
                    </TableCell>
                    <TableCell>{formatDate(transaction.date)}</TableCell>
                    <TableCell>
                      {transaction.type === TransactionType.Distribution
                        ? `$${formatNumber(transaction.creditDelta!)}`
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {transaction.type === TransactionType.Topup
                        ? `$${formatNumber(transaction.creditDelta!)}`
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {transaction.credit
                        ? `$${formatNumber(transaction.credit!)}`
                        : "-"}
                    </TableCell>
                    <TableCell className="text-right">
                      {transaction.availableForWithdrawal
                        ? `$${formatNumber(transaction.availableForWithdrawal)}`
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() => {
                      setTransactionPage(transactionPage - 1);
                    }}
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink>{transactionPage}</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationNext
                    onClick={() => {
                      setTransactionPage(transactionPage + 1);
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </CardContent>
        </Card>

        <Card className="xl:col-span-2">
          <CardHeader className="flex flex-row items-center justify-between">
            <div className="grid gap-2">
              <CardTitle>{organization?.name}: Users</CardTitle>
            </div>
            <Sheet>
              <SheetTrigger asChild>
                <Button variant="outline" className="bg-black text-white">
                  Add User
                </Button>
              </SheetTrigger>
              <SheetContent side="bottom">
                <SheetHeader>
                  <SheetTitle>Add User</SheetTitle>
                </SheetHeader>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="username" className="text-right">
                      Username
                    </Label>
                    <Input
                      id="username"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => {
                        setUser(e.target.value);
                      }}
                      className="col-span-3"
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="email" className="text-right">
                      Email
                    </Label>
                    <Input
                      id="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className="col-span-3"
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="Role" className="text-right">
                      Role
                    </Label>
                    <Select
                      onValueChange={(v) => {
                        setRole(v);
                      }}
                    >
                      <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Role" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="SUPER_ADMIN">Super Admin</SelectItem>
                        <SelectItem value="ADMIN">Admin</SelectItem>
                        <SelectItem value="USER">User</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <SheetFooter>
                  <SheetClose asChild>
                    <Button
                      type="submit"
                      onClick={async () => {
                        if (!id) return;
                        await addUser({
                          username,
                          email,
                          role,
                          organizationId: id,
                        });
                      }}
                    >
                      Save changes
                    </Button>
                  </SheetClose>
                </SheetFooter>
              </SheetContent>
            </Sheet>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Username</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Role</TableHead>
                  <TableHead>Is Active</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {users?.map((user, idx) => (
                  <TableRow
                    key={idx}
                    onClick={() => {
                      navigate(`/user/${user.userId}`);
                    }}
                  >
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>{`${user.isActive}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() => {
                      setUserPage(userPage - 1);
                    }}
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink>{userPage}</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationNext
                    onClick={() => {
                      setUserPage(userPage + 1);
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </CardContent>
        </Card>

        <Card className="xl:col-span-2">
          <CardHeader className="flex flex-row items-center">
            <div className="grid gap-2">
              <CardTitle>{organization?.name}: Logs</CardTitle>
            </div>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>id</TableHead>
                  <TableHead>Username</TableHead>
                  <TableHead>Action</TableHead>
                  <TableHead>Date</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {logs?.map((log, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{log.id}</TableCell>
                    <TableCell>{log.username}</TableCell>
                    <TableCell>{log.action}</TableCell>
                    <TableCell>{log.date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() => {
                      setLogPage(logPage - 1);
                    }}
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink>{logPage}</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationNext
                    onClick={() => {
                      setLogPage(logPage + 1);
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </CardContent>
        </Card>
      </main>
      <Transactor
        title="Execute Withdrawl"
        open={withdrawMint !== null}
        transactionStatus={transactionStatus}
        setTransactionStatus={setTransactionStatus}
        setTxHash={setTxHash}
        cleanUp={() => {
          setWithdrawMint(null);
        }}
        executeTransaction={async (transport: Transport) => {
          try {
            await executeWithdrawl(transport, withdrawMint ?? 0);
          } catch (e) {
            console.error(e);
            toast({
              title: "Error",
              description: (e as Error).message,
              variant: "destructive",
            });
          }
        }}
        txHash={txHash}
      />
      <Toaster />
    </div>
  );
}
